.googleVerificationWrapper {
  width: 450px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 25px;

  .googleVerificationWrapper__Header {
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #101828;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      padding: 5px 0px;
    }
  }

  .googleVerificationWrapper__QRcode {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    img {
      width: 45%;
    }
  }

  .googleVerificationWrapper__Otp {
    width: 100%;
    // text-align: left;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }

    .googleVerificationWrapper__OtpDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .googleVerificationWrapper__DownLayout {
    margin: 30px 0px 15px 0px;
    width: 100%;
    .googleVerificationWrapper__DownLayoutTextSection {
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #f3ba2f;
      }

      a {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #f3ba2f;
        text-decoration: underline;
      }
    }

    .googleVerificationWrapper__DownLayoutButtonSection {
      width: 100%;
      padding: 15px 0px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .googleVerificationWrapper__DownLayoutButtonSectionCancel {
        width: 48%;
        height: 40px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #344054;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        cursor: pointer;
        outline: none;
      }

      .googleVerificationWrapper__DownLayoutButtonSectionConfirm {
        width: 48%;
        height: 40px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        outline: none;

        &:hover {
          background: #996afe;
          border: 1px solid #996afe;
        }

        &:disabled {
          background: #ffffff;
          border: 1px solid #d0d5dd;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          color: #9a9a9b;
        }
      }
    }
  }
}
