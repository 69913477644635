.googleComfirmationWrapper {
  width: 450px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 25px 15px;

  .googleComfirmationWrapper__ImageSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 50%;
      object-fit: cover;
    }
  }

  .googleComfirmationWrapper__Details {
    padding: 10px 0px;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #101828;
    }
  }

  .googleComfirmationWrapper__Action {
    margin: 30px 0px 20px 0px;
    padding: 15px 0px 5px 0px;

    button {
      background: #7f56d9;
      border: 1px solid #7f56d9;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      height: 40px;
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background: #996afe;
        border: 1px solid #996afe;
      }
    }
  }
}
