.login-input-style {
  width: 100%;
  height: 48px;
  font-size: 14px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 10px;
  border: none;
  background: #f1f3f6;
  outline: none;
}
