.login-container {
  display: flex;
  // width: 100%;
  height: 100vh;
  // background-color: peru;
  justify-content: center;
  align-items: center;
  position: relative;

  .topLogo {
    position: absolute;
    top: 0;
    left: 1px;
    width: 150px;
    height: 50px;
    margin-left: 38px;
    margin-top: 10px;
  }

  .left-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // width: 50%;
  }

  .right-container {
    flex: 1.2;
    // object-fit: cover;
    // max-width: fit-content;
    background-color: #5c2ec4;
    // width: 50%;
    height: 100%;

    .innerContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 2rem;
      height: 100vh;
      .sentenceStyle {
        color: white;
        // font-size: 4rem;
        font-size: 40px;
        font-weight: 400;
        width: 50%;
        margin-right: 2rem;
      }
    }
  }

  .login-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .form-contents {
      width: 400px;
      height: auto;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 26px;
        font-weight: 700;
        color: #000;
        line-height: 31.47px;
        text-align: left;
        width: 100%;
      }

      .tag {
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        text-align: left;
        width: 100%;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        text-align: left;
        width: 100%;
      }

      .form-input {
        width: 100%;
        margin-top: 30px;

        .label {
          font-size: 13px;
          font-weight: 500;
          color: #344054;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .login-container {
    .login-content {
      width: 100%;
      height: 60vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .form-contents {
        width: 400px;
        height: auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 25px;
          font-weight: 600;
          color: #101828;
          line-height: 80px;
        }

        .tag {
          font-size: 16px;
          font-weight: 400;
          color: #667085;
          text-align: center;
        }

        .form-input {
          width: 100%;
          margin-top: 30px;

          .label {
            font-size: 14px;
            font-weight: 500;
            color: #344054;
          }
        }
      }
    }
  }

  .right-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .login-container {
    .login-content {
      margin-top: 60px;
      align-items: flex-start;
      align-items: center;

      .form-contents {
        .title {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .login-container {
    .login-content {
      .form-contents {
        width: 94%;
      }
    }
  }
}
